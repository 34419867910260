// Here you can add other styles
.btn-outline-danger {
    --cui-btn-color: #e55353;
    --cui-btn-border-color: #e55353;
    --cui-btn-hover-color: #e55353;
    --cui-btn-hover-bg: #ffffff00;
    --cui-btn-hover-border-color: #e86464;
    --cui-btn-focus-shadow-rgb: 255, 254, 252;
    --cui-btn-active-color: #000015;
    --cui-btn-active-bg: #ea7575;
    --cui-btn-active-border-color: #e86464;
    --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
    --cui-btn-disabled-color: #e55353;
    --cui-btn-disabled-bg: transparent;
    --cui-gradient: none;
}
.btn-outline-info {
  --cui-btn-color: #39f;
  --cui-btn-border-color: #39f;
  --cui-btn-hover-color: #39f;
  --cui-btn-hover-bg: #ffffff00;
  --cui-btn-hover-border-color: #47a3ff;
  --cui-btn-focus-shadow-rgb: 255, 254, 252;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #5cadff;
  --cui-btn-active-border-color: #47a3ff;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #39f;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}
.MuiOutlinedInput-notchedOutline legend {
    float: unset !important;
}
